
import { instance } from '@/utils/http.js'

// 获取一级知识点
export const getKnowledge = function (params) {
  return instance({
    url: '/api/v1/public/get_knowledge1',
    method: 'get',
    params
  })
}
//新获取知识点
export const getNewKnowledge = function (params) {
  return instance({
    url: '/api/v1/public/get_knowledge',
    method: 'get',
    params
  })
}

// 考点训练
export const knowledgeTrain = function (data) {
  return instance({
    url: '/api/v1/xmode/knowledge_train_two',
    method: 'post',
    data
  })
}

// 获取下级知识点
export const getSonKnowledge = function (params) {
  return instance({
    url: '/api/v1/public/get_son_knowledge',
    method: 'get',
    params
  })
}
