<template>
  <div>
    <el-dialog title="三级考点"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="1200rem"
               :before-close="handleClose">
      <el-table :data="tableData"
                height="450rem"
                ref="theTable"
                default-expand-all
                @row-click="getOpenDetail"
                :tree-props="{children: 'child'}"
                row-key="knowledge_id"
                style="width: 100%">
        <el-table-column prop="text"
                         align="left"
                         label="考点">
          <template slot-scope="{row}">
            <div class="checkpoint">
            
              {{ `${row.knowledge_desc}(${row.level}级考点)`}}

            </div>
          </template>
        </el-table-column>
        <el-table-column prop="text"
                         align="left"
                         label="易错指数">
          <template slot-scope="{row}">
            <div class="checkpoint">
              {{row.error_prone}}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="200"
                         label="操作">
          <template slot-scope="{row}">
            <div class="btn"
                 v-if="row.level !== 2"
                 @click="doPaper(row)">
              开始训练
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>

import { getSonKnowledge } from '@/api/knowledge.js'
export default {
  data () {
    return {
      form: {},
      tableData: [],
      dialogVisible: false,
    }
  },
  methods: {

    getOpenDetail (row) {
      this.$refs.theTable.toggleRowExpansion(row);
    },
    handleClose () {
      this.dialogVisible = false
    },
    doPaper (row) {
      let params = {
        subject_id: row.subject_id,
        knowledge_id: row.knowledge_id,
        is_weak: 0,
        type: 4,
      }
      this.handleClose()
      this.$router.push('/doPaper?type=5&form=' + JSON.stringify(params))
    },
    async initList (row) {
      // let { data } = await getSonKnowledge(params)
      this.tableData = [...row]
      this.tableData = this.tableData.filter(item => item.child)
      this.tableData.map(item => { item.expanded = false })

    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20rem;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4rem;
}
// ::v-deep .el-table__expand-icon {
//   display: inline !important;
// }
// ::v-deep .el-icon-arrow-right {
//   display: inline !important;
// }
::v-deep .el-dialog {
  border-radius: 20rem;
  height: 600rem;
  margin-top: 198rem !important;
  overflow: hidden;
}
::v-deep .el-dialog__body {
  padding: 30rem 20px;
}

::v-deep .el-table th,
.el-table tr {
  background: #f6f6f6;
}
::v-deep .cell {
  // text-indent: 60rem;
  display: flex;
  font-size: 20rem;
}

.checkpoint {
  // display: inline;
  font-weight: bold;
  color: #333333;
  font-size: 20rem;
}
.btn {
  width: 140rem;
  height: 44rem;
  background: #2196f3;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20rem;
  font-weight: bold;
  color: #ffffff;
  text-indent: 0;
  cursor: pointer;
}
</style>